/** @format */

import React from 'react';
import { navigate } from 'gatsby-link';

const Card = ({ heading, paragraph, imgUrl, projectLink, caseStudy }) => {
    return (
        <div onClick={() => navigate(`/${projectLink}`)} className="card">
            <div
                className="photo"
                style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.2)), url(${imgUrl})`,
                }}></div>
            <div className="content-container">
                <div className="content">
                    <h2 className="header">{heading}</h2>
                    <h3>{caseStudy}</h3>
                    <p className="text">{paragraph}</p>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Card;
