/** @format */

import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';

const Header = () => {
    return (
        <div className="section" id="home">
            <div className="container">
                <div className="header-wrapper">
                    <Fade bottom>
                        <h2>
                            Hi, I'm {data.name}{' '}
                            <span role="img" aria-label="Emoji">
                                👋
                            </span>
                        </h2>
                    </Fade>
                    <Fade bottom cascade>
                        <div className="heading-wrapper">
                            <h1>
                                {data.headerTagline[0]
                                    ? data.headerTagline[0]
                                    : 'Building digital'}
                            </h1>
                            <h1>
                                {' '}
                                {data.headerTagline[1]
                                    ? data.headerTagline[1]
                                    : 'products, brands'}
                            </h1>
                        </div>
                    </Fade>
                    <Fade bottom>
                        <p>
                            I'm a <strong>Product Designer</strong> and{' '}
                            <strong>Front-End Developer</strong> with a passion
                            for design, technology, and entrepreneurship.
                            También hablo español!
                        </p>
                    </Fade>
                    <Fade bottom>
                        <a
                            href={`mailto:${
                                data.contactEmail
                                    ? data.contactEmail
                                    : 'ian@ndrepont.com'
                            }`}
                            className="primary-btn">
                            Connect With Me
                        </a>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default Header;
