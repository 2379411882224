/** @format */

import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';

const Footer = () => {
    return (
        <div className="section" id="contact">
            <div className="container">
                <div className="footer-container">
                    {/* <Fade bottom cascade> */}
                    <h2>Contact</h2>
                    {/* </Fade> */}
                    <a
                        className="email-link"
                        href={`mailto:${data.contactEmail}`}>
                        {data.contactEmail}
                    </a>
                    {/* <p>(337) 849-0304</p> */}
                    <div className="social-icons">
                        {data.social.map((socialLink, index) => (
                            <a
                                key={index}
                                href={socialLink.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                <img src={socialLink.img} alt="icons"></img>
                            </a>
                        ))}
                    </div>
                    {/* <span>
            Made With <icon>❤</icon> by{" "}
            <a href="https://www.ianandrepont.com/">Ian Andrepont</a>
          </span> */}
                </div>
            </div>
        </div>
    );
};

export default Footer;
