/** @format */

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
// Components
import Header from '../components/Header';
import Work from '../components/Work';
import About from '../components/about';
import Skills from '../components/skills';
import Testimonials from '../components/testimonials';
import Promotion from '../components/Promotion';
import Footer from '../components/Footer';

const IndexPage = () => (
    <Layout>
        <SEO title="Ian's Portfolio" />
        <Header></Header>
        <Work></Work>
        {/* <Skills></Skills> */}
        {/* <Testimonials></Testimonials> */}
        <Promotion></Promotion>
        <Footer></Footer>
    </Layout>
);

export default IndexPage;
